var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"event-container"}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"id":"events-loader","size":70,"color":"black","indeterminate":""}}):_vm._e(),_c('div',{staticClass:"d-flex title-area"},[(_vm.breakpoint.smAndUp)?_c('BackButton',{staticClass:"ml-2 mr-6",on:{"click":function($event){return _vm.$router.go(-1)}}}):_vm._e(),_c('div',{staticClass:"event-title"},[_c('div',{staticClass:"event-title-number"},[_vm._v(_vm._s(_vm.ufcEventNumber))]),_c('div',[_vm._v(_vm._s(_vm.ufcFighterTitle))])]),_c('div',{staticClass:"table-view-switch-container"},[(_vm.isTableView || _vm.breakpoint.smAndUp)?_c('v-switch',{staticClass:"table-view-switch",attrs:{"label":"Table View"},model:{value:(_vm.isTableView),callback:function ($$v) {_vm.isTableView=$$v},expression:"isTableView"}}):_vm._e()],1)],1),(_vm.isTableView)?_c('FightsTable',{staticClass:"mb-6",attrs:{"data":_vm.tableData}}):_c('div',[(!_vm.fights.filter((fight) => fight.card == 'MAIN CARD').length)?_c('section',_vm._l((_vm.fights),function(fight,index){return _c('FightCard',{key:index,attrs:{"fight":fight,"event":_vm.event,"index":index,"show-details":false,"requiresPurchase":_vm.requiresPurchase},on:{"show-purchase":function($event){return _vm.showPurchaseEventDialog(index)}}})}),1):_c('div',[(_vm.fights.filter((fight) => fight.card == 'MAIN CARD').length)?_c('section',[_c('h1',{staticClass:"pb-4 pt-4 text-center sticky"},[_vm._v("Main Card")]),_vm._l((_vm.fights.filter(
            (fight) => fight.card == 'MAIN CARD'
          )),function(fight,index){return _c('FightCard',{key:index,attrs:{"fight":fight,"event":_vm.event,"index":_vm.fights.findIndex((fightFromBigList) => fightFromBigList == fight),"show-details":false,"requiresPurchase":_vm.requiresPurchase},on:{"show-purchase":function($event){return _vm.showPurchaseEventDialog(index)}}})})],2):_vm._e(),(_vm.fights.filter((fight) => fight.card == 'PRELIMS').length)?_c('section',[_c('h1',{staticClass:"pb-4 pt-4 text-center sticky"},[_vm._v("Prelims")]),_vm._l((_vm.fights.filter(
            (fight) => fight.card == 'PRELIMS'
          )),function(fight,index){return _c('FightCard',{key:index,attrs:{"fight":fight,"event":_vm.event,"index":_vm.fights.findIndex((fightFromBigList) => fightFromBigList == fight),"show-details":false,"requiresPurchase":_vm.requiresPurchase},on:{"show-purchase":function($event){return _vm.showPurchaseEventDialog(index)}}})})],2):_vm._e(),(_vm.fights.filter((fight) => fight.card == 'EARLY PRELIMS').length)?_c('section',[_c('h1',{staticClass:"pb-4 pt-4 text-center sticky"},[_vm._v("Early Prelims")]),_vm._l((_vm.fights.filter(
            (fight) => fight.card == 'EARLY PRELIMS'
          )),function(fight,index){return _c('FightCard',{key:index,attrs:{"fight":fight,"event":_vm.event,"index":_vm.fights.findIndex((fightFromBigList) => fightFromBigList == fight),"show-details":false,"requiresPurchase":_vm.requiresPurchase},on:{"show-purchase":function($event){return _vm.showPurchaseEventDialog(index)}}})})],2):_vm._e()]),(!_vm.fights || _vm.fights.length <= 0)?_c('div',{staticClass:"text-center"},[_vm._v(" No data ")]):_vm._e()]),_c('v-dialog',{attrs:{"width":"500px","overlay-opacity":"0.9"},model:{value:(_vm.showPurchaseEvent),callback:function ($$v) {_vm.showPurchaseEvent=$$v},expression:"showPurchaseEvent"}},[_c('PurchaseEvent',{attrs:{"event":_vm.event,"event-id":_vm.$route.params.eventId,"fight-id":_vm.selectedFight,"alert":_vm.alert}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }