<template>
  <div>
    <v-card
      class="event-card"
      :to="to"
      :style="{ 'background-image': `url(${event.imageURL})` }"
      :class="{ 'event-card--with-image': !!event.imageURL }"
    >
      <div
        v-if="event.predicted && !showAccuracy"
        class="event__predicted-indicator predictions-ready"
      >
        Predictions Ready
      </div>
      <div
        class="event__accuracy-container"
        v-if="showAccuracy && event.accuracy && event.predicted"
      >
        <div class="event__accuracy-indicator mr-2">
          <!-- v-if="event.accuracy.mainCard > event.accuracy.total" -->
          Main Card: {{ Math.round(event.accuracy.mainCard * 100) }}%
        </div>
        <div class="event__accuracy-indicator">
          <!-- v-if="event.accuracy.mainCard < event.accuracy.total" -->
          Prelims: {{ Math.round(event.accuracy.prelims * 100) }}%
        </div>
      </div>

      <!-- <div
        v-if="showAccuracy && event.accuracy && event.predicted"
        class="event__predicted-indicator prelims"
      >
        Prelims Accuracy: {{ Math.round(event.accuracy.prelims * 100) }} %
      </div> -->
      <div class="additional-info">{{ ufcEventNumber }}</div>
      <div class="fighter-name__title">{{ ufcFighterTitle }}</div>
      <div class="additional-info">{{ ufcDate }}</div>
      <div class="additional-info">{{ ufcTime }}</div>
    </v-card>
  </div>
</template>
<script>
import { format } from "date-fns";
export default {
  name: "EventCard",
  props: {
    eventId: String,
    event: Object,
    to: String,
  },
  data: () => ({
    accuracy: 0,
    showAccuracy: false,
  }),
  mounted() {
    const todayInSeconds = new Date().getTime() / 1000;
    const sixHoursInSeconds = 6 * 60 * 60;
    if (todayInSeconds > this.event.dateTimeSeconds + sixHoursInSeconds) {
      this.showAccuracy = true;
    }
  },
  computed: {
    ufcEventNumber() {
      const ppvMatch = this.event.name.match("UFC [0-9]{3}");
      const fightNightMatch = this.event.name.match("UFC Fight Night");

      const match = ppvMatch || fightNightMatch;

      return match ? match[0] : "";
    },
    ufcFighterTitle() {
      const ppvMatch = this.event.name.match("UFC [0-9]{3}");
      const fightNightMatch = this.event.name.match("UFC Fight Night");

      const match = ppvMatch || fightNightMatch;

      return match ? this.event.name.replace(match[0], "") : this.event.name;
    },
    ufcDate() {
      const date = new Date(null);
      date.setSeconds(this.event.dateTimeSeconds);
      return format(date, "MMM do");
    },
    ufcTime() {
      const date = new Date(null);
      date.setSeconds(this.event.dateTimeSeconds);
      return format(date, "p");
    },
  },
};
</script>
<style>
.event-card {
  margin: 5px;
  padding: 20px;
  text-align: center;

  /* background-position: center; */
  background-position-y: 30%;
  background-size: cover;
}

.event-card--with-image {
  padding: 50px;
}

.event-card--with-image,
.event-card--with-image .additional-info {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  /* text-shadow: -1px 0 rgb(73, 73, 73), 0 1px rgb(73, 73, 73), 1px 0 rgb(73, 73, 73), 0 -1px rgb(73, 73, 73); */
  color: white !important;
}

.fighter-name__title {
  font-weight: bold;
  font-size: 26px;
}

.additional-info {
  font-weight: bold;
  color: gray;
}

.event__predicted-indicator {
  position: absolute;
  top: 16px;
  background-color: darkblue;
  padding: 2px 8px;
  border-radius: 5px;
  border: 2px solid white;
  color: white;
  text-shadow: none;
  font-weight: bold;
}

.event__accuracy-container {
  position: absolute;
  margin: auto;
  top: 12px;
  width: 100%;
  left: 0;
}

.event__accuracy-indicator {
  /* position: absolute;
  top: 16px; */
  display: inline-block;
  background-color: darkblue;
  padding: 2px 8px;
  border-radius: 5px;
  border: 2px solid white;
  color: white;
  text-shadow: none;
  font-weight: bold;
}

.event__predicted-indicator.main-card {
  /* right: 220px; */
  right: 16px;
}

.event__predicted-indicator.prelims,
.event__predicted-indicator.predictions-ready {
  right: 16px;
}
</style>