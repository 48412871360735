<template>
  <div id="event-container">
    <v-progress-circular
      id="events-loader"
      v-if="loading"
      :size="70"
      color="black"
      indeterminate
    ></v-progress-circular>

    <div class="d-flex title-area">
      <BackButton
        v-if="breakpoint.smAndUp"
        @click="$router.go(-1)"
        class="ml-2 mr-6"
      />
      <div class="event-title">
        <div class="event-title-number">{{ ufcEventNumber }}</div>
        <div>{{ ufcFighterTitle }}</div>
      </div>
      <div class="table-view-switch-container">
        <v-switch
          v-if="isTableView || breakpoint.smAndUp"
          v-model="isTableView"
          label="Table View"
          class="table-view-switch"
        ></v-switch>
      </div>
    </div>
    <FightsTable v-if="isTableView" :data="tableData" class="mb-6" />
    <div v-else>
      <section
        v-if="!fights.filter((fight) => fight.card == 'MAIN CARD').length"
      >
        <FightCard
          :key="index"
          v-for="(fight, index) in fights"
          :fight="fight"
          :event="event"
          :index="index"
          :show-details="false"
          :requiresPurchase="requiresPurchase"
          v-on:show-purchase="showPurchaseEventDialog(index)"
        />
      </section>
      <div v-else>
        <section
          v-if="fights.filter((fight) => fight.card == 'MAIN CARD').length"
        >
          <h1 class="pb-4 pt-4 text-center sticky">Main Card</h1>
          <FightCard
            :key="index"
            v-for="(fight, index) in fights.filter(
              (fight) => fight.card == 'MAIN CARD'
            )"
            :fight="fight"
            :event="event"
            :index="
              fights.findIndex((fightFromBigList) => fightFromBigList == fight)
            "
            :show-details="false"
            :requiresPurchase="requiresPurchase"
            v-on:show-purchase="showPurchaseEventDialog(index)"
          />
        </section>
        <section
          v-if="fights.filter((fight) => fight.card == 'PRELIMS').length"
        >
          <h1 class="pb-4 pt-4 text-center sticky">Prelims</h1>
          <FightCard
            :key="index"
            v-for="(fight, index) in fights.filter(
              (fight) => fight.card == 'PRELIMS'
            )"
            :fight="fight"
            :event="event"
            :index="
              fights.findIndex((fightFromBigList) => fightFromBigList == fight)
            "
            :show-details="false"
            :requiresPurchase="requiresPurchase"
            v-on:show-purchase="showPurchaseEventDialog(index)"
          />
        </section>
        <section
          v-if="fights.filter((fight) => fight.card == 'EARLY PRELIMS').length"
        >
          <h1 class="pb-4 pt-4 text-center sticky">Early Prelims</h1>
          <FightCard
            :key="index"
            v-for="(fight, index) in fights.filter(
              (fight) => fight.card == 'EARLY PRELIMS'
            )"
            :fight="fight"
            :event="event"
            :index="
              fights.findIndex((fightFromBigList) => fightFromBigList == fight)
            "
            :show-details="false"
            :requiresPurchase="requiresPurchase"
            v-on:show-purchase="showPurchaseEventDialog(index)"
          />
        </section>
      </div>
      <div v-if="!fights || fights.length <= 0" class="text-center">
        No data
      </div>
    </div>

    <v-dialog v-model="showPurchaseEvent" width="500px" overlay-opacity="0.9">
      <!-- <PurchaseEvent
        :event="event"
        :event-id="$route.params.eventId"
        :fight-id="selectedFight"
        :alert="alert"
        :price="price"
      /> -->
      <PurchaseEvent
        :event="event"
        :event-id="$route.params.eventId"
        :fight-id="selectedFight"
        :alert="alert"
      />
    </v-dialog>
  </div>
</template>
<script>
import FightCard from "../../components/authenticated/FightCard";
import PurchaseEvent from "../../components/authenticated/PurchaseEvent";
import FightsTable from "../../components/authenticated/FightsTable";
import BackButton from "../../components/authenticated/BackButton.vue";
import { getEvent } from "../../services/database";
// import { getPrice } from "../../services/utils";
import { logEvent } from "../../services/analytics";
import { scrollToPosition } from "../../services/scroll";

export default {
  name: "Event",
  props: {
    user: Object,
    alert: Function,
  },
  components: {
    FightCard,
    PurchaseEvent,
    FightsTable,
    BackButton,
  },
  watch: {
    isTableView() {
      localStorage.setItem("isTableView", this.isTableView);
      this.$emit("fullScreen", this.isTableView);

      logEvent("select_item", {
        items: [
          {
            item_id: this.event.id,
            item_name: this.event.name,
            is_table_view: this.isTableView,
          },
        ],
      });
    },
  },
  beforeDestroy() {
    this.$emit("fullScreen", false);
  },
  data: function () {
    return {
      fights: [],
      event: {},
      loading: false,
      showPurchaseEvent: false,
      selectedFight: null,
      // price: null,
      isTableView: false,
      breakpoint: {},
    };
  },
  computed: {
    requiresPurchase() {
      // const isEventPurchased = this.user.purchases.find(
      //   (purchase) => purchase.items[0].item_id == this.$route.params.eventId
      // );

      // return (
      //   !isEventPurchased &&
      //   this.event.date_timestamp >= new Date().getTime() / 1000
      // );

      return false;
    },
    ufcEventNumber() {
      if (this.event && this.event.name) {
        const ppvMatch = this.event.name.match("UFC [0-9]{3}");
        const fightNightMatch = this.event.name.match("UFC Fight Night");

        const match = ppvMatch || fightNightMatch;

        return match ? match[0] : "";
      }

      return "";
    },
    ufcFighterTitle() {
      if (this.event && this.event.name) {
        const ppvMatch = this.event.name.match("UFC [0-9]{3}");
        const fightNightMatch = this.event.name.match("UFC Fight Night");

        const match = ppvMatch || fightNightMatch;

        return match ? this.event.name.replace(match[0], "") : this.event.name;
      }

      return "";
    },
    tableData() {
      return this.fights.map((fight, index) => {
        const redName =
          fight.fighters.red.name.given + " " + fight.fighters.red.name.family;
        const blueName =
          fight.fighters.blue.name.given +
          " " +
          fight.fighters.blue.name.family;

        const redImage = fight.fighters.red.imageURL;
        const blueImage = fight.fighters.blue.imageURL;

        let judge1, judge2, judge3, predictedWinner, confidence;

        if (fight.prediction) {
          predictedWinner = fight.prediction.winner;
          judge1 = fight.prediction.judge1;
          judge2 = fight.prediction.judge2;
          judge3 = fight.prediction.judge3;
          confidence = Math.round(fight.prediction.confidence * 100) + "%";
        }

        let winner = null;
        if (fight.winner == "red") {
          winner = redName;
        } else if (fight.winner == "blue") {
          winner = blueName;
        }

        return {
          index,
          fight: { redName, blueName, redImage, blueImage },
          judge1,
          judge2,
          judge3,
          prediction: predictedWinner,
          confidence: confidence,
          winner,
        };
      });
    },
  },
  methods: {
    showPurchaseEventDialog(fightId) {
      this.selectedFight = fightId;
      this.showPurchaseEvent = true;
    },
  },
  mounted() {
    this.breakpoint = this.$vuetify.breakpoint;

    this.loading = true;
    this.isTableView =
      localStorage.isTableView && localStorage.isTableView.length
        ? JSON.parse(localStorage.isTableView)
        : false;

    // const getEventPromise = getEvent(this.$route.params.eventId);
    // const getPricePromise = getPrice();

    // Promise.all([getEventPromise, getPricePromise])
    // .then(([[fights, event], price]) => {
    getEvent(this.$route.params.eventId)
      .then((event) => {
        this.event = event;
        this.fights = event.fights;
        // this.price = "£" + price.unit_amount / 100;

        logEvent("select_item", {
          items: [
            {
              item_id: event.id,
              item_name: event.name,
              is_table_view: this.isTableView,
            },
          ],
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.loading = false;

        scrollToPosition.bind(this)();
      });
  },
};
</script>
<style>
#events-loader {
  margin-top: 80px;
}

.title-area {
  padding: 14px;
  align-items: center;
  position: sticky;
  top: 56px;
  background-color: white;
  border-bottom: solid 1px rgb(202, 202, 202);
  margin-bottom: 6px;
  z-index: 2;
}

.event-title {
  font-size: 24px;
  font-weight: bold;
}

.event-title-number {
  font-weight: normal;
  font-size: 18px;
}

.table-view-switch-container {
  flex: 1;
  text-align: right;
}

.table-view-switch {
  display: inline-block !important;
}

.sticky {
  top: 148px;
  z-index: 1;
  background: white;
  position: sticky;
}
</style>